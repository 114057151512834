import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryResult } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }

  signin(signinData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}auth/login`, signinData);
  }

  sendAccessCode(payload): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}auth/sendAccessCode`, payload);
  }

  verifyAccessCode(payload): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}auth/verifyAccessCode`, payload);
  }

  forgotPassword(email: string): Observable<QueryResult> {
    const params: HttpParams = new HttpParams()
      .set('email', email);
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}auth/forgotPassword`, null, { params: params });
  }

}
