import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavToolbarComponent } from './components/nav-toolbar/nav-toolbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    NavToolbarComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NavToolbarComponent
  ]
})
export class CodiceToolbarModule { }
