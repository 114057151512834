import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryResult, ReferenceType, ReferenceValue } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  constructor(private httpClient: HttpClient) { }

  getByTypesArray(referenceValueTypes: ReferenceType[]): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}ReferenceValue/getByReferenceValueType`, referenceValueTypes);
  }

  // Below values in hardcoded until getting form backend. This is for demonstration only
  get(type: ReferenceType): ReferenceValue[] {
    switch (type) {
      case ReferenceType.FacilityOwnership:
        return [
          { value: '1', description: 'Owned' },
          { value: '2', description: 'Leased' },
          { value: '3', description: 'Depreciated' },
        ];
      case ReferenceType.LicensedOrApprovedAs:
        return [
          { value: '1', description: 'Skilled Care Facility' },
          { value: '2', description: 'Hospital-Based Skilled Nursing Facility' },
        ];
      case ReferenceType.LevelOfCertification:
        return [
          { value: '1', description: 'Skilled Care' },
          { value: '2', description: 'Intermediate Care' },
        ];
      case ReferenceType.OrganizationType:
        return [
          { value: '1', description: 'Voluntary, Non-Profit' },
          { value: '2', description: 'Governmental' },
          { value: '3', description: 'Proprietary' },
          { value: '4', description: 'Individual' },
          { value: '5', description: 'Partnership' },
          { value: '6', description: 'Corporation' },
          { value: '7', description: 'Other' },
        ];
      case ReferenceType.State:
        return [
          { value: '1', description: 'Alaska' },
          { value: '2', description: 'Alabama' },
          { value: '3', description: 'Arkansas' },
          { value: '4', description: 'Arizona' },
          { value: '5', description: 'California' },
          { value: '6', description: 'Colorado' },
          { value: '7', description: 'Connecticut' },
          { value: '8', description: 'District of Columbia' },
          { value: '9', description: 'Delaware' },
          { value: '10', description: 'Florida' },
          { value: '11', description: 'Georgia' },
          { value: '12', description: 'Hawaii' },
          { value: '13', description: 'Iowa' },
          { value: '14', description: 'Idaho' },
          { value: '15', description: 'Illinois' },
          { value: '16', description: 'Indiana' },
          { value: '17', description: 'Kansas' },
          { value: '18', description: 'Kentucky' },
          { value: '19', description: 'Louisiana' },
          { value: '21', description: 'Massachusetts' },
          { value: '22', description: 'Maryland' },
          { value: '23', description: 'Maine' },
          { value: '24', description: 'Michigan' },
          { value: '25', description: 'Minnesota' },
          { value: '26', description: 'Missouri' },
          { value: '27', description: 'Mississippi' },
          { value: '28', description: 'Montana' },
          { value: '29', description: 'North Carolina' },
          { value: '30', description: 'North Dakota' },
          { value: '31', description: 'Nebraska' },
          { value: '32', description: 'New Hampshire' },
          { value: '33', description: 'New Jersey' },
          { value: '34', description: 'New Mexico' },
          { value: '35', description: 'Nevada' },
          { value: '36', description: 'New York' },
          { value: '37', description: 'Ohio' },
          { value: '38', description: 'Oklahoma' },
          { value: '39', description: 'Oregon' },
          { value: '40', description: 'Pennsylvania' },
          { value: '41', description: 'Rhode Island' },
          { value: '42', description: 'South Carolina' },
          { value: '43', description: 'South Dakota' },
          { value: '44', description: 'Tennessee' },
          { value: '45', description: 'Texas' },
          { value: '46', description: 'Utah' },
          { value: '47', description: 'Virginia' },
          { value: '48', description: 'Vermont' },
          { value: '49', description: 'Washington' },
          { value: '50', description: 'Wisconsin' },
          { value: '51', description: 'West Virginia' },
          { value: '52', description: 'Wyoming' }
        ];
      case ReferenceType.RUG:
        return [
          { value: '1', description: 'BA1' },
          { value: '2', description: 'BA2' },
          { value: '3', description: 'BB1' },
          { value: '4', description: 'BB2' },
          { value: '5', description: 'CA1' },
          { value: '6', description: 'CA2' },
          { value: '7', description: 'CB1' },
          { value: '8', description: 'CB2' },
          { value: '9', description: 'CC1' },
          { value: '10', description: 'CC2' },
          { value: '11', description: 'CD1' },
          { value: '12', description: 'CD2' },
          { value: '13', description: 'CE1' },
          { value: '14', description: 'CE2' },
          { value: '15', description: 'ES1' },
          { value: '16', description: 'ES2' },
          { value: '17', description: 'ES3' },
          { value: '18', description: 'HB1' },
          { value: '19', description: 'HB2' },
          { value: '20', description: 'HC1' },
          { value: '21', description: 'HC2' },
          { value: '22', description: 'HD1' },
          { value: '23', description: 'HD2' },
          { value: '24', description: 'HE1' },
          { value: '25', description: 'HE2' },
          { value: '26', description: 'LB1' },
          { value: '27', description: 'LB2' },
          { value: '28', description: 'LC1' },
          { value: '29', description: 'LC2' },
          { value: '30', description: 'LD1' },
          { value: '31', description: 'LD2' },
          { value: '32', description: 'LE1' },
          { value: '33', description: 'LE2' },
          { value: '34', description: 'PA1' },
          { value: '35', description: 'PA2' },
          { value: '36', description: 'PB1' },
          { value: '37', description: 'PB2' },
          { value: '38', description: 'PC1' },
          { value: '39', description: 'PC2' },
          { value: '40', description: 'PD1' },
          { value: '41', description: 'PD2' },
          { value: '42', description: 'PE1' },
          { value: '43', description: 'PE2' },
          { value: '44', description: 'RAA' },
          { value: '45', description: 'RAB' },
          { value: '46', description: 'RAC' },
          { value: '47', description: 'RAD' },
          { value: '48', description: 'RAE' },
          { value: '49', description: 'AAA' }
        ];
      case ReferenceType.LevelOfCare:
        return [
          { value: '1', description: 'Skilled Nursing ' },
          { value: '2', description: 'Intermediate Care' },
          { value: '3', description: 'Other' }
        ];
      default:
        break;
    }
  }

  getByTypeAndValue(type: ReferenceType, value: string): ReferenceValue {
    return this.get(type).filter(x => x.value == value)[0]
  }
}
