import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'codice-portlet',
  template: `
    <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
      <mat-drawer-container class="flex-auto h-full dark:bg-transparent" [ngClass]="{'bg-card': isBgCard}">
        <mat-drawer-content class="flex flex-col">
          <ng-content></ng-content>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  `
})
export class CodicePortletComponent implements OnInit {

  @Input() isBgCard: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
