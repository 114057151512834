import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthDataService } from '@celerkost/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authDataService: AuthDataService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isMultipartContent = request.headers.keys().map(x => x == "multipart/form-data"); //TODO: Need to bind authorization in the multipart request
        if (!isMultipartContent) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.authDataService.token ?? null}`,
                },
            });
        }else{
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this.authDataService.token ?? null}`,
                },
            });
        }

        return next.handle(request);
    }

}
