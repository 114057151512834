import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  get(url: string, header: any = { 'content-Type': 'application/json' }): Observable<any> {
    return this.http.get(url, this.createHeader(header));
  }

  post(url: string, body: any, header: any = { 'content-Type': 'application/json' }): Observable<any> {
    return this.http.post(url, body, this.createHeader(header));
  }

  put(url: string, body: any, header: any = { 'content-Type': 'application/json' }): Observable<any> {
    return this.http.put(url, body, this.createHeader(header));
  }

  delete(url: string, header: any = { 'content-Type': 'application/json' }): Observable<any> {
    return this.http.delete(url, this.createHeader(header));
  }

  private createHeader(headers: any): any {
    headers = headers || {};
    const x = { ...headers };
    return {
      headers: new HttpHeaders(x)
    };
  }

}
