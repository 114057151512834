import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, EntityQueryResult, Facility, QueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from '../data';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService
  ) { }

  add(facilityData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}facility/add`, facilityData);
  }

  update(facilityData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}facility/update`, facilityData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}facility/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntityQueryResult<Facility>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntityQueryResult<Facility>>(`${environment.baseApiURL}facility/getById`, { params: params });
  }
  getAllByProviderId(provideId: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('providerId', provideId);
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}facility/getAllByProvider`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}facility/delete`, { params: params });
  }

}
