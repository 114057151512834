<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <!-- Light version -->
            <img class="dark:hidden w-50" src="assets/images/logo/logo-text.png" alt="Logo image">
            <!-- Dark version -->
            <img class="hidden dark:flex w-50" src="assets/images/logo/logo-text-on-dark.png" alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative fixed flex dark fixed flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <languages></languages> -->
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
        class="relative flex fixed flex-0 items-center justify-between w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <div class="font-medium text-secondary">
            Codice CelerKost &copy; {{currentYear}}
        </div>
        <div>
            <span *ngIf="envTag?.active" class="rounded-lg p-1" [class]="this.envTag?.color">{{ envTag?.name }}</span>
            <span class="font-medium text-secondary ml-2">Version {{version}}</span>
        </div>
    </div>

</div>

<!-- Quick chat -->
<!-- <settings></settings> -->