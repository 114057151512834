import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, EntityQueryResult, ExpenseEntryCapital, QueryResult } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from '@celerkost/services';

@Injectable({
  providedIn: 'root'
})
export class ExpenseEntryCapitalService {

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService
  ) { }

  add(expenseEntryCapital): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}expenseEntryCapital/add`, expenseEntryCapital);
  }

  update(expenseEntryCapitalData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}expenseEntryCapital/update`, expenseEntryCapitalData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}expenseEntryCapital/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntityQueryResult<ExpenseEntryCapital>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntityQueryResult<ExpenseEntryCapital>>(`${environment.baseApiURL}expenseEntryCapital/getById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}expenseEntryCapital/delete`, { params: params });
  }

  getNetExpense(request): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}expenseEntryCapital/getNetExpense`, request);
  }

}
