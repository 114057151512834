import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntitySimpleQueryResult, QueryResult } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CostReportService {

  constructor(private httpClient: HttpClient) { }

  getNetExpensesSummery(searchQuery: any): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}costReport/getNetExpensesSummery`, searchQuery);
  }
  getCostReportDetailsForBasicAndReimbursements(searchQuery: any): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}costReport/GetCostReportDetailsForBasicAndReimbursements`, searchQuery);
  }
}
