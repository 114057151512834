import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthDataService } from '@celerkost/services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        public authDataService: AuthDataService,
        public router: Router
    ) {
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authDataService.isValidUser()) {
            return true;
        } else {
            this.router.navigate(['./']);
            return false;
        }
    }

    canActivateChild(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authDataService.isValidUserWithToken()) {
            return true;
        } else {
            this.router.navigate(['./']);
            return false;
        }
    }

}
