import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryResult } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CostReportYearService {

  constructor(private httpClient: HttpClient) { }

  getAllByProviderId(providerId: string): Observable<QueryResult> {
    const params: HttpParams = new HttpParams()
      .set('providerId', providerId);
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}CostReportYear/GetAllByProviderId`, { params: params });
  }

  getById(id: string): Observable<QueryResult> {
    const params: HttpParams = new HttpParams()
      .set('providerId', id);
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}CostReportYear/getById`, { params: params });
  }
}
