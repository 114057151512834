import { AuthGuard, AuthorizeGuard, NoAuthGuard } from '@celerkost/guards';
import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { Permissions } from '@celerkost/constants';

export const appRoutes: Route[] = [

    // Default route
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth'
    },

    // Auth routes
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
    },

    // Landing routes
    {
        path: '',
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'home',
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
        ]
    },

    // Nursing Home routes
    {
        path: '',
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'nursing-home'
        },
        children: [
            { path: 'nursing-home', loadChildren: () => import('app/modules/nursing-homes/nursing-home.module').then(m => m.NursingHomeModule) },
        ]
    },

    // Wildcard route
    {
        path: '**',
        redirectTo: 'auth'
    }
];
