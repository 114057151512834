import { NavToolbarButtonType } from '../constants/nav-toolbar-button.type';
import { NavToolbarButtonTemplateType } from './../constants/nav-toolbar-button-template.type';
import { NavToolbarButton } from "./nav-toolbar";

const templates: {
  button: NavToolbarButton,
  type: NavToolbarButtonTemplateType
}[] = [
    {
      button: { Title: 'New', Icon: 'heroicons_outline:plus', Color: 'accent', ButtonType: NavToolbarButtonType.New },
      type: NavToolbarButtonTemplateType.New
    },
    {
      button: { Title: 'Refresh', Icon: 'heroicons_outline:refresh', Color: 'accent', ButtonType: NavToolbarButtonType.Refresh },
      type: NavToolbarButtonTemplateType.Refresh
    },
    {
      button: { Title: 'Save', Icon: 'feather:save', Color: 'accent', ButtonType: NavToolbarButtonType.Add },
      type: NavToolbarButtonTemplateType.Add
    },
    {
      button: { Title: 'Save and Close', Icon: 'heroicons_outline:save-as', Color: 'accent', ButtonType: NavToolbarButtonType.AddExit },
      type: NavToolbarButtonTemplateType.AddExit
    },
    {
      button: { Title: 'Edit', Icon: 'mat_solid:edit', Color: 'accent', ButtonType: NavToolbarButtonType.Edit },
      type: NavToolbarButtonTemplateType.Edit
    },
    {
      button: { Title: 'Assign', Icon: 'heroicons_outline:clipboard-copy', Color: 'accent', ButtonType: NavToolbarButtonType.Assign },
      type: NavToolbarButtonTemplateType.Assign
    },
    {
      button: { Title: 'Confirm', Icon: 'heroicons_outline:check-circle', Color: 'accent', ButtonType: NavToolbarButtonType.Confirm },
      type: NavToolbarButtonTemplateType.Confirm
    },
    {
      button: { Title: 'Cancel', Icon: 'heroicons_outline:x', Color: 'light', ButtonType: NavToolbarButtonType.Cancel },
      type: NavToolbarButtonTemplateType.Cancel
    },
    {
      button: { Title: 'Back', Icon: 'mat_solid:arrow_back_ios', Color: 'light', ButtonType: NavToolbarButtonType.Back },
      type: NavToolbarButtonTemplateType.Back
    },
    {
      button: { Title: 'Export To PDF', Icon: 'mat_solid:download', Color: 'primary', ButtonType: NavToolbarButtonType.ExportToPdf },
      type: NavToolbarButtonTemplateType.ExportToPdf
    },
  ];

export class ToolbarButtonTemplates {
  static getButtonByTemplate(type: NavToolbarButtonTemplateType): NavToolbarButton {
    return templates.find(v => v.type === type)?.button;
  }
}
