import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionNotificationComponent } from '../components/action-notification/action-notification.component';
import { MessageType } from '../constants/message-type';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  show(
    message: string,
    type: MessageType,
    duration: number = 4000,
    showCloseButton: boolean = true,
    verticalPosition: 'top' | 'bottom' = 'top',
  ) {

    const data = {
      message,
      snackBar: this.snackBar,
      showCloseButton,
      verticalPosition,
      type,
      icon: 'mat_solid:info',
    };

    let matSnackBarClass;

    switch (type) {
      case MessageType.Success:
        matSnackBarClass = 'mat-snack-bar-container-success';
        data.icon = 'mat_solid:check_circle';
        break;
      case MessageType.Info:
        matSnackBarClass = 'mat-snack-bar-container-info';
        data.icon = 'mat_solid:info';
        break;
      case MessageType.Warning:
        matSnackBarClass = 'mat-snack-bar-container-warning';
        data.icon = 'mat_solid:warning';
        break;
      case MessageType.Error:
        matSnackBarClass = 'mat-snack-bar-container-error';
        data.icon = 'mat_solid:error';
        break;
      case MessageType.General:
        data.icon = 'mat_solid:error_outline';
        data.verticalPosition = 'bottom';
        break;
    }

    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration,
      data,
      verticalPosition: data.verticalPosition,
      horizontalPosition: 'center',
      panelClass: matSnackBarClass
    });
  }

}
