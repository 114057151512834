import { CanDeactivateGuard } from './guards/can-component-deactivate.guard';
import { CodicePortletModule, CodiceSkeltonLoaderModule, CodiceToolbarModule } from '@celerkost/libs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ActionNotificationComponent } from './libs/codice-snackbar/components/action-notification/action-notification.component';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        CodiceToolbarModule,
        CodicePortletModule,
        CodiceSkeltonLoaderModule,
        NgxMaskModule.forRoot(),
        FuseScrollbarModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CodiceToolbarModule,
        CodicePortletModule,
        NgxMaskModule,
        MatTooltipModule,
        MatIconModule,
        CodiceSkeltonLoaderModule,
        FuseScrollbarModule
    ],
    declarations: [
        ActionNotificationComponent
    ],
    providers: [
        CanDeactivateGuard
    ]
})
export class SharedModule {
}
