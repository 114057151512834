import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, EntitySimpleQueryResult, ExpenseEntryAdjustment, QueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from '@celerkost/services';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpenseAndRevenueAdjustmentService {

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService
  ) { }

  add(expenseAndRevenueAdjustmentData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}expenseEntryExpenseAndRevenueAdjustment/Add`, expenseAndRevenueAdjustmentData);
  }
  update(expenseEntryNursingAndResidentCareData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}ExpenseEntryExpenseAndRevenueAdjustment/update`, expenseEntryNursingAndResidentCareData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}ExpenseEntryExpenseAndRevenueAdjustment/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntitySimpleQueryResult<ExpenseEntryAdjustment>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntitySimpleQueryResult<ExpenseEntryAdjustment>>(`${environment.baseApiURL}ExpenseEntryExpenseAndRevenueAdjustment/GetById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}ExpenseEntryExpenseAndRevenueAdjustment/delete`, { params: params });
  }
}
