import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from 'app/services/data';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ReimbursementService {

  constructor(private httpClient: HttpClient, private authDataService: AuthDataService) { }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}Reimbursement/GetAll`, searchQuery);
  }
  getAllGroupByMedicaidId(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}Reimbursement/GroupByMedicaidIdGetAll`, searchQuery);
  }
}
