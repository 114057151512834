import { FuseConfirmationConfig } from "@fuse/services/confirmation";

export const leavePageConfirmationConfig: FuseConfirmationConfig = {
    title: 'Confirm Navigation',
    message: 'You will lose your unsaved data. Are you sure want to leave this page?',
    icon: { color: 'basic', name: 'warning_amber' },
    actions: {
        confirm: {
            label: 'Leave this Page',
            color: 'accent'
        }
    }
};