import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, ExpenseEntryAdministration, QueryResult,EntitySimpleQueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from 'app/services/data';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpenseEntryAdministrationService {
  constructor(   
    private httpClient: HttpClient,
    private authDataService: AuthDataService
    ) { }

  add(expenseEntryAdministrationData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}ExpenseEntryAdministration/Add`, expenseEntryAdministrationData);
  }
  
  update(expenseEntryNursingAndResidentCareData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}ExpenseEntryAdministration/update`, expenseEntryNursingAndResidentCareData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}ExpenseEntryAdministration/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntitySimpleQueryResult<ExpenseEntryAdministration>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntitySimpleQueryResult<ExpenseEntryAdministration>>(`${environment.baseApiURL}ExpenseEntryAdministration/GetById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}ExpenseEntryAdministration/delete`, { params: params });
  }

  getNetExpense(request): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}ExpenseEntryAdministration/getNetExpense`, request);
  }

}
