import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NavToolbarButtonType } from '../../constants';
import { NavToolbarButton } from '../../models';
import { ToolbarService } from '../../services';

@Component({
  selector: 'codice-nav-toolbar',
  templateUrl: './nav-toolbar.component.html',
  styleUrls: ['./nav-toolbar.component.scss']
})
export class NavToolbarComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() enableBack: boolean = false;
  @Input() enableDrawerToggle: boolean = false;

  @Output() toggleMatDrawer = new EventEmitter();

  activeButtonList: Observable<NavToolbarButton[]>;
  loadingButtonList: Observable<NavToolbarButton[]>;
  subscription: Subscription = new Subscription();

  constructor(
    private toolbarService: ToolbarService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activeButtonList = this.toolbarService.visibleButtons.asObservable();
    this.loadingButtonList = this.toolbarService.loadingButtons.asObservable();
  }

  isButtonInArray(button: NavToolbarButton, array: NavToolbarButton[]): boolean {
    return array?.find((btn: NavToolbarButton) => btn == button) != null;
  }

  onButtonClick(type: NavToolbarButtonType): void {
    this.toolbarService.onValueChange(type);
  }

  goBack(): void {
    this.location.back();
  }

  toggleDrawer(): void {
    this.toggleMatDrawer.emit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.toolbarService.reset();
  }

}
