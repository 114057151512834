export const environment = {
    production: false,
    version: '1.7.53',
    baseApiURL: 'http://dev-alpha.celerkost.com:7000/api/v1/',
    encryptStorageKey: 'LSSID5BFLarLWhcgH2owmzLOdlZi0QfU',

    tag: {
        active: true,
        name: 'Development',
        color: 'bg-blue-400'
    }
};