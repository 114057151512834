export enum NursingAndResidentCareType {
    Other = 14
}

export enum RoutineAndSupportType {
    Other = 16
}

export enum NonAllowableType {
    Other = 12
}

export enum AdministrationType {
    Other = 15,
    HomeOffice = 4
}

export enum HomeOfficeType {
    Other = 30
}

export enum AdjustmentTypeRevenue {
    Other = 14
}

export enum AdjustmentTypeExpense {
    Other = 23
}

export enum CapitalType {
    EquipmentRental = 2,
    FacilityRental = 3,
    Other = 8
}

export enum AllocationBasisType {
    SquareFootage = 1,
    ResidentDays = 2,
    MealsServed = 3,
    AccumulatedCost = 4
}