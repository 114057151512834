import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, EntityQueryResult, QueryResult, CashReceipt } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthDataService } from '@celerkost/services';

@Injectable({
  providedIn: 'root'
})
export class CashReceiptService {

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService
  ) { }

  add(cashReceiptData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}cashReceipt/add`, cashReceiptData);
  }

  update(cashReceiptData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}cashReceipt/update`, cashReceiptData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}cashReceipt/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntityQueryResult<CashReceipt>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntityQueryResult<CashReceipt>>(`${environment.baseApiURL}cashReceipt/getById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}cashReceipt/delete`, { params: params });
  }

}
