import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private data: BehaviorSubject<any> = new BehaviorSubject(null);
  private dataV2: BehaviorSubject<any> = new BehaviorSubject(null);

  get data$(): Observable<any> {
    return this.data.asObservable();
  }

  get dataV2$(): Observable<any> {
    return this.dataV2.asObservable();
  }

  constructor(private _httpClient: HttpClient) { }

  getProjectData(): Observable<any> {
    return this._httpClient.get('api/dashboards/project').pipe(
      tap((response: any) => {
        this.data.next(response);
      })
    );
  }

  getAnalyticsData(): Observable<any> {
    return this._httpClient.get('api/dashboards/analytics').pipe(
      tap((response: any) => {
        this.dataV2.next(response);
      })
    );
  }

}